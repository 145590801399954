import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
  getToken,
} from "firebase/app-check";
import {
  NextOrObserver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
  UserCredential,
  getAuth,
  getMultiFactorResolver,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getFirebaseConfig } from "./firebase-config";

const config = getFirebaseConfig();
const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, config.BACKEND_REGION);
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);


if (config.APPCHECK_E2E_OVERIDE) {
  (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = config.APPCHECK_E2E_OVERIDE;
  console.log("FIREBASE_APPCHECK_DEBUG_TOKEN: " + (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN);
} else if (window.location.hostname === "localhost") {
  (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(config.RECAPTCHA_KEY!),
  isTokenAutoRefreshEnabled: true,
});

const db = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
  ignoreUndefinedProperties: true,
});


export { firebaseApp as app, auth, db, functions };

type MultiFactorResult = {
  multiFactorRequired: boolean;
  resolver: any;
};

export const signInUser = async (email: string, password: string): Promise<UserCredential | MultiFactorResult> => {
  if (!email || !password) {
    throw new Error("Email and password are required");
  }

  try {
    // Ensure a valid App Check token is obtained before signing in
    const token = await getToken(appCheck, /* forceRefresh= */ true);
    console.log("Valid App Check token: ", token.token);

    return await signInWithEmailAndPassword(auth, email, password);
  } catch (error: any) {
    if (error.code === "auth/multi-factor-auth-required") {
      const resolver = getMultiFactorResolver(auth, error);
      return { multiFactorRequired: true, resolver };
    } else {
      throw error;
    }
  }
};

export const setupRecaptchaVerifier = () => {
  return new RecaptchaVerifier(auth, "mfa-captcha", { size: "invisible" });
};

export const verifyPhoneNumber = async (resolver: any, verifier: any) => {
  const mfInfo = resolver.hints.find(
    (f: any) => f.factorId === PhoneMultiFactorGenerator.FACTOR_ID
  );
  const phoneInfoOptions = {
    multiFactorHint: mfInfo,
    session: resolver.session,
  };
  const phoneAuthProvider = new PhoneAuthProvider(auth);
  return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verifier);
};

export const verifyTwoFactorCode = async (verificationId: string, verificationCode: string, resolver: any) => {
  const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  return await resolver.resolveSignIn(multiFactorAssertion);
};

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback);
};

export const SignOutUser = async () => await signOut(auth);
