import { useEffect, useContext, useState } from "react";
import { getAuth, signInWithCustomToken, User } from 'firebase/auth';

import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Menu } from "@/components/dashboard/menu";
import { OverviewMain } from "@/components/overview/main";
import { Sidebar } from "@/components/sidebar/sidebar";
import { LongtermMain } from "@/components/longterm/main";
import { AuthContext } from "@/context/auth-context";
import useGetHeatmap from "@/hooks/heatmap";
import { useSaveRules, useSite } from "@/hooks/site";
import { IRules, ISiteHeatmapData } from "@/lib/types";
import { ISite } from "@ehabitation/ts-utils/browser";
import { ShareIcon } from "lucide-react";
import { SiteLoadStatus } from "@/components/overview/site-load-status";
import { IntroVideoSection } from "@/components/dashboard/intro-video-section";
import { useEvents } from "@/context/events-context";
import ClimateHeatmapSection from "@/components/overview/heatmap/climate-heatmap-section";

export default function Dashboard() {
  // Authentication states
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Existing states
  const [rules, setRules] = useState<IRules>({});
  const [site, setSite] = useState<ISite | undefined>();
  const [siteHeatmap, setSiteHeatmap] = useState<ISiteHeatmapData>();

  const { currentUser } = useContext(AuthContext);
  const { events } = useEvents();

  // Handle token authentication
  useEffect(() => {
    const handleTokenAuth = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      if (token) {
        setIsAuthenticating(true);
        try {
          const auth = getAuth();
          await signInWithCustomToken(auth, token);
          window.history.replaceState({}, document.title, '/dashboard');
        } catch (error) {
          console.error('Error authenticating with custom token:', error);
          window.location.href = '/login';
        } finally {
          setIsAuthenticating(false);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    handleTokenAuth();
  }, []);

  // Existing hooks
  useSite(currentUser, setSite, setRules, site);
  useSaveRules(rules, currentUser, site);
  useGetHeatmap(rules, setSiteHeatmap, site);

  // Show loading state while authenticating
  if (isAuthenticating || isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-2">Loading...</h2>
          <p className="text-gray-600">Please wait while we set up your dashboard</p>
        </div>
      </div>
    );
  }

  // Show login redirect if no user
  if (!currentUser) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-2">Not Authenticated</h2>
          <p className="text-gray-600">Please log in to access the dashboard</p>
          <button 
            onClick={() => window.location.href = '/login'}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Go to Login
          </button>
        </div>
      </div>
    );
  }

  // Main dashboard content
  return (
    <>
      <div className="hidden md:block flex-wrap">
        <Menu />
        <div className="border-t">
          <div className="bg-background">
            <div className="grid lg:grid-cols-9">
              <div className="col-span-3 lg:col-span-3 pr-4">
                <Sidebar
                  site={site}
                  setSite={setSite}
                  rules={rules}
                  setRules={setRules}
                  user={currentUser}
                />
              </div>
              <div className="col-span-6 lg:col-span-6 lg:border-l">
                <div className="h-full px-4 py-6 lg:px-8">
                  {!site && (
                    <IntroVideoSection/>
                  )}
                  {site?.status == "pending" && (
                    <SiteLoadStatus site={site} />
                  )}
                  {site?.status == "open" && (
                    <Tabs defaultValue="overview" className="h-full space-y-6">
                      <div className="space-between flex items-center">
                        <TabsList>
                          <TabsTrigger value="overview" className="relative">
                            Historical analysis
                          </TabsTrigger>
                          <TabsTrigger value="longterm">Climate modeling</TabsTrigger>
                          <TabsTrigger value="climate_heatmaps">
                            Climate Heatmaps
                          </TabsTrigger>
                        </TabsList>
                        <div className="ml-auto mr-4" hidden>
                          <Button
                            variant="ghost"
                            onClick={() => {
                              events.export(site.id)
                              window.print()
                            }}
                          >
                            <ShareIcon className="mr-2 h-4 w-4" />
                            Export
                          </Button>
                        </div>
                      </div>

                      <TabsContent
                        value="overview"
                        className="border-none p-0 outline-none"
                      >
                        <OverviewMain site={site} siteHeatmap={siteHeatmap} />
                      </TabsContent>

                      <TabsContent
                        value="longterm"
                        className="h-full flex-col border-none p-0 data-[state=active]:flex"
                      >
                        <LongtermMain site={site} />
                        <Separator className="my-4" />
                      </TabsContent>
                      
                      <TabsContent
                        value="climate_heatmaps"
                        className="h-full flex-col border-none p-0 data-[state=active]:flex"
                      >
                        <ClimateHeatmapSection site={site} rules={rules}/>
                        <Separator className="my-4" />
                      </TabsContent>
                    </Tabs>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}