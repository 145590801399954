import { UserAuthForm } from "@/components/login/user-auth-form";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useEffect, useState, useContext } from "react";
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "@/context/auth-context";

function Home() {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const handleTokenAuth = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      if (token) {
        setIsAuthenticating(true);
        try {
          const auth = getAuth();
          await signInWithCustomToken(auth, token);
          window.history.replaceState({}, document.title, '/');
          navigate('/dashboard');
        } catch (error) {
          console.error('Error authenticating with custom token:', error);
          setIsAuthenticating(false);
        }
      }
    };

    // If user is already authenticated, redirect to dashboard
    if (currentUser) {
      navigate('/dashboard');
      return;
    }

    handleTokenAuth();
  }, [currentUser, navigate]);

  // Show loading state while authenticating
  if (isAuthenticating) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-2">Authenticating...</h2>
          <p className="text-muted-foreground">Please wait while we sign you in</p>
        </div>
      </div>
    );
  }

  // Regular login page if no token or authentication failed
  return (
    <>
      <div className="container relative hidden h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-zinc-900" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <img src="/ehab-white-logo.svg" className="h-[30px]" alt="EHAB Logo" />
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Every region in the world is projected to face further
                increases in climate hazards.&rdquo;
              </p>
              <footer className="text-sm">
                Intergovernmental Panel on Climate Change (IPCC) - Report 2023
              </footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Sign into EHAB
              </h1>
              <p className="text-sm text-muted-foreground">
                {/* Enter your email below to sign in to ehab */}
              </p>
            </div>
            <UserAuthForm />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <a
                href="https://drive.google.com/file/d/1W7lZ3wxJwJ3umICHpGlsF4fCgPMw0_cb/view?usp=sharing"
                target="_blank"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://ehab.co/privacy-policy/"
                target="_blank"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;